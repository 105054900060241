import { Component, Inject } from '@angular/core';
import { Constant } from './../../constant';
import { CommonUtil } from '../../util/common-util';
import { ToastUtil } from '../../util/toast-util';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../../auth/auth.service';
import { PdfviewerDialogComponent } from '../pdfviewer-dialog/pdfviewer-dialog.component';
import { GeneralYesNoDialogComponent } from '../general-yes-no-dialog/general-yes-no-dialog.component';

@Component({
  selector: 'app-material-detail-dialog',
  templateUrl: './material-detail-dialog.component.html',
  styleUrl: './material-detail-dialog.component.css'
})
export class MaterialDetailDialogComponent {
  public index;
  public material;
  public readonly Constant = Constant;
  public showSpinner = true;
  public documentUrl = null;
  public documentTotalPage = 0;
  public documentPage = 1;
  public showDocumentSpinner = false;
  private apiPath = '/document';


  constructor(
    public dialogRef: MatDialogRef<MaterialDetailDialogComponent>,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private dialog: MatDialog,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.index = data.index;
    this.material = data.material;
  }

  ngOnInit() {
    this.getDetail();
  }

  onClose() {
    this.dialogRef.close();
  }

  // 編集画面に切り替え
  onEdit() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    const param = {
      isEdit: true,
      index: this.index
    };
    this.dialogRef.close(param);
  }

  // ステータス変更
  onRelease(isRelease) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    const param = {
      isEdit: false,
      index: this.index,
      isRelease: isRelease
    };
    let message = {};
    if (isRelease) {
      message = Constant.dcMsgComfirmRelease;
    } else {
      message = Constant.dcMsgComfirmStop;
    }
    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: { msg: message }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        this.dialogRef.close(param);
      }
    });
  }


  /* API */
  // 詳細取得
  private getDetail() {
    if (!this.commonUtil.isOnline()) {
      this.showSpinner = false;
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.dialogRef.close();
      return;
    }

    Auth.currentSession().then(session => {
      const apiPath = this.apiPath + '/detail/' + this.data.id;
      const options = this.auth.createApiHeader(session);
      this.commonUtil.apiGet(apiPath, options).then(res => {
        if (!res.data) {
          // 削除済み
          this.toastUtil.showErrorToast(Constant.empty, Constant.msgDeleteMaterialAlreadyError, Constant.toastShowMiliSec);
          const param = {
            reload: true
          };
          this.dialogRef.close(param);
          return;
        }
        this.material = res.data;
        this.documentUrl = res.data.document_url;
        this.showSpinner = false;
        this.showDocumentSpinner = true;
      })
      .catch(err => {
        this.commonUtil.debug().log(err);
        this.showSpinner = false;
        this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      });
    });
  }

  /* PDF関連 */
  pdfLoadComplete(pdfData) {
    this.documentTotalPage = pdfData.numPages;
    this.showDocumentSpinner = false;
  }

  public pdfError() {
    if (this.documentTotalPage === 0) {
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
    }
  }
  public prevBtn() {
    this.documentPage--;
  }
  public nextBtn() {
    this.documentPage++;
  }
  // PDF全画面ダイアログ表示
  public onPdf() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      return;
    }

    const dialogRef = this.dialog.open(PdfviewerDialogComponent, {
      width: '100%',
      maxWidth: '99vw',
      maxHeight: '99%',
      autoFocus: false,
      panelClass: 'pdf-dialog',
      data: { url: this.documentUrl }
    });
  }

}
