import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './login/login.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { TalentMapComponent } from './talent-map/talent-map.component';
import { TalentListComponent } from './talent-list/talent-list.component';
import { TalentDetailComponent } from './talent-detail/talent-detail.component';
import { NoticeUrlComponent } from './notice-url/notice-url.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { LpComponent } from './lp/lp.component';
import { JobOfferComponent } from './job-offer/job-offer.component';
import { CompanyComponent } from './company/company.component';
import { PrContentsComponent } from './pr-contents/pr-contents.component';
import { RequestComponent } from './request/request.component';
import { DownloadComponent } from './download/download.component';
import { MultiMessageComponent } from './multi-message/multi-message.component';
import { MultiMessageTalentComponent } from './multi-message-talent/multi-message-talent.component';
import { MultiMessageSendComponent } from './multi-message-send/multi-message-send.component';
import { MultiMessageExceptComponent } from './multi-message-except/multi-message-except.component';
import { UserguideComponent } from './userguide/userguide.component';
import { Constant } from './constant';
import { LpCaseComponent } from './lp-case/lp-case.component';
import { LpFaqComponent } from './lp-faq/lp-faq.component';
import { ShowFileComponent } from './show-file/show-file.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { QuestionComponent } from './question/question.component';
import { CsvImportTalentComponent } from './csv-import-talent/csv-import-talent.component';
import { PrContentsCategoryComponent } from './pr-contents-category/pr-contents-category.component';
import { MaterialsComponent } from './materials/materials.component';

const routes: Routes = [
  { path: 'login/:code', component: LoginComponent },
  { path: 'login', redirectTo: '/' },
  { path: 'password_change', component: PasswordChangeComponent },
  { path: 'map', component: TalentMapComponent, canActivate: [AuthGuard] },
  { path: 'list', component: TalentListComponent, canActivate: [AuthGuard], canDeactivate: [AuthGuard] },
  {
    path: 'detail/c/:id',
    component: TalentDetailComponent,
    data: { csv_flag: Constant.tlRegistTypeCsv },
    canActivate: [AuthGuard]
  },
  {
    path: 'detail/:id',
    component: TalentDetailComponent,
    data: { csv_flag: Constant.tlRegistTypeTalent },
    canActivate: [AuthGuard]
  },
  {
    path: 'pr_contents',
    component: PrContentsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rtPrContentsCategory,
    component: PrContentsCategoryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rtMaterials,
    component: MaterialsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'job_offer',
    component: JobOfferComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company',
    component: CompanyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'notice_url',
    component: NoticeUrlComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rtQuestion,
    component: QuestionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rtAnalytics,
    component: AnalyticsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'message',
    component: MultiMessageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'message_talent',
    component: MultiMessageTalentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'message_send',
    component: MultiMessageSendComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'message_except',
    component: MultiMessageExceptComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'guide',
    component: UserguideComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rtTmpFile,
    component: ShowFileComponent,
    data: { type: Constant.fileTypeMessage },
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rtMsgFile + '/:id',
    component: ShowFileComponent,
    data: { type: Constant.fileTypeMessage },
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rtAttachment + '/:id',
    component: ShowFileComponent,
    data: { type: Constant.fileTypeProfile },
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rtImportTalent,
    component: CsvImportTalentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: Constant.rtRequest + '/:mode',
    component: RequestComponent
  },
  {
    path: Constant.rtDownload,
    component: DownloadComponent
  },
  { path: Constant.rtLpNewgrad, component: LpComponent, data: { type: Constant.LpModeNewgrad } },
  { path: Constant.rtLpCareer, component: LpComponent, data: { type: Constant.LpModeCareer } },
  { path: Constant.rtCase, component: LpCaseComponent },
  { path: Constant.rtCase + '/:id', component: LpCaseComponent },
  { path: 'faq', component: LpFaqComponent },
  { path: '', component: LpComponent, data: { type: Constant.LpModeTop } },
  { path: 'error', component: PageNotFoundComponent },
  { path: 'maintenance', component: MaintenanceComponent },
  { path: '**', redirectTo: '/error' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
