import { Component, HostListener } from '@angular/core';
import { Constant } from '../constant';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { Auth } from '@aws-amplify/auth';
import { CommonUtil } from '../util/common-util';
import { AuthService } from '../auth/auth.service';
import { ToastUtil } from '../util/toast-util';
import { MaterialEditDialogComponent } from '../dialog/material-edit-dialog/material-edit-dialog.component';
import { MaterialDetailDialogComponent } from '../dialog/material-detail-dialog/material-detail-dialog.component';
import { GeneralYesNoDialogComponent } from '../dialog/general-yes-no-dialog/general-yes-no-dialog.component';

@Component({
  selector: 'app-materials',
  templateUrl: './materials.component.html',
  styleUrl: './materials.component.css'
})
export class MaterialsComponent {
  public readonly Constant = Constant;
  public showSpinner = true;
  public materials;
  private apiPath = '/document';

  constructor(
    private auth: AuthService,
    private title: Title,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private dialog: MatDialog,
  ) {
    this.title.setTitle(Constant.pageTitleMaterials + Constant.pageTitleCommon);
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.commonUtil.checkRefreshToken(this.auth);
  }

  ngOnInit() {
    window.scroll(0, 0);
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    // 資料データを取得する
    this.getMaterial();
  }

  // 新規作成/編集
  onEdit(index?) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    let type;
    let material_id = 0;
    if (index != null) {
      material_id = this.materials[index].id;
      type = Constant.dcTypeEdit;
    } else {
      type = Constant.dcTypeAdd;
    }

    const dialogRef = this.dialog.open(MaterialEditDialogComponent, {
      width: Constant.jobOfferDialogWidth,
      maxHeight: Constant.jobOfferDialogMaxHeight,
      minHeight: Constant.jobOfferDialogMinHeight,
      autoFocus: false,
      disableClose: true,
      data: {
        type: type,
        id: material_id
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== Constant.empty) {
        this.getMaterial();
      }
    });
  }

  // 詳細画面表示
  onDetail(index) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    const material = this.materials[index];

    const dialogRef = this.dialog.open(MaterialDetailDialogComponent, {
      width: Constant.jobOfferDialogWidth,
      maxHeight: Constant.jobOfferDialogMaxHeight,
      minHeight: Constant.jobOfferDialogMinHeight,
      autoFocus: false,
      data: {
        index: index,
        id: this.materials[index].id,
        material: material
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        if (result.reload) {
          // 一覧再読み込み
          this.getMaterial();
        } else if (result.isEdit) {
          // 編集画面を表示する
          this.onEdit(result.index);
        } else {
          // ステータス更新
          this.releaseExec(result.index, result.isRelease);
        }
      }
    });
  }

  // 削除
  onDel(index) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: { msg: Constant.dcMsgComfirmDel }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        // 削除のAPI呼び出し
        this.delMaterial(this.materials[index].id);
      }
    });
  }

  // ステータス変更
  onRelease(index, isRelease) {
    let message = {};
    if (isRelease) {
      message = Constant.dcMsgComfirmRelease;
    } else {
      message = Constant.dcMsgComfirmStop;
    }
    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: { msg: message }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        this.releaseExec(index, isRelease);
      }
    });
  }

  // 詳細URLをコピー
  onUrlCopy(index: string) {
    let element: HTMLElement;
    element = document.getElementById('url-code-' + index);

    if (element) {
      const temp = document.createElement('div');
      temp.appendChild(document.createElement('span')).textContent = element['value'];
      document.body.appendChild(temp);
      document.getSelection().selectAllChildren(temp);
      const succeeded = document.execCommand('copy');

      if (succeeded) {
        // コピー成功
        this.toastUtil.clearAllShowingToast();
        this.toastUtil.showInformationToast('', Constant.msgCopySuccess, Constant.toastShowMiliSec);
      } else {
        // コピー失敗
        this.toastUtil.clearAllShowingToast();
        this.toastUtil.showErrorToast('', Constant.msgCopyError, Constant.toastShowMiliSec);
      }
      document.body.removeChild(temp);
    }
  }

  /* API */
  // 資料一覧を取得する
  private getMaterial() {
    if (!this.commonUtil.isOnline()) {
      this.showSpinner = false;
      return;
    }

    // ぐるぐる表示
    this.showSpinner = true;
    this.materials = null;
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      this.commonUtil
        .apiGet(this.apiPath, options)
        .then(res => {
          this.materials = res.data;
          this.showSpinner = false;
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.showSpinner = false;
          this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
        });
    });
  }

  // 公開ステータス変更
  private releaseExec(index, isRelease) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    this.showSpinner = true;
    Auth.currentSession().then(session => {
      const apiPath = this.apiPath + '/status';
      const options = this.auth.createApiHeader(session);
      const email = localStorage.getItem(Constant.lsOperator);
      let toastMessage = Constant.empty;
      options['body'] = {
        id: this.materials[index].id,
        email: email
      };
      if (isRelease) {
        options['body']['release_status'] = Constant.pcReleaseStatusRelease;
        toastMessage = Constant.msgStatusRelease;
      } else {
        options['body']['release_status'] = Constant.pcReleaseStatusStop;
        toastMessage = Constant.msgStatusNotRelease;
      }
      this.commonUtil
        .apiPut(apiPath, options)
        .then(res => {
          this.showToast(toastMessage);
          this.getMaterial();
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          if (err.status === Constant.NG) {
            this.showErrorToast(Constant.msgNoticeStatusFailed);
          } else {
            this.showErrorToast(Constant.msgNoticeStatusFailedRetry);
          }
          this.getMaterial();
        });
    });
  }

  // 削除API呼び出し
  private delMaterial(id) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    this.showSpinner = true;
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        document_id: id
      };

      this.commonUtil
        .apiDel(this.apiPath, options)
        .then(res => {
          this.showToast(Constant.msgNoticeDel);
          this.getMaterial();
        })
        .catch(err => {
          this.showErrorToast(Constant.msgNoticeDelFailed2);
          this.getMaterial();
        });
    });
  }


  /* private */
  // トースト表示
  private showToast(msg) {
    this.toastUtil.clearAllShowingToast();
    this.toastUtil.showInformationToast(Constant.empty, msg, Constant.toastShowMiliSec);
  }

  // エラートースト表示
  private showErrorToast(msg) {
    this.toastUtil.clearAllShowingToast();
    this.toastUtil.showErrorToast(Constant.empty, msg, Constant.toastShowMiliSec);
  }
}
