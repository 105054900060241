<ng-container *ngIf="lp; else main">
  <router-outlet></router-outlet>
</ng-container>
<ng-template #main>
  <div class="main">
    <app-header></app-header>
    <div class="contents">
      <mat-sidenav-container>
        <mat-sidenav
          class="sidenav-container"
          #sidenav
          mode="side"
          opened="true"
          [fixedInViewport]="true"
          [fixedTopGap]="50"
          [fixedBottomGap]="0"
          *ngIf="!menuDisabled"
        >
          <div class="sidenav-content">
            <mat-nav-list>
              <ng-container *ngIf="(auth.loggedIn | async)">
                <a mat-list-item [routerLink]="['/map']" routerLinkActive="active">タレントマップ</a>
                <mat-divider></mat-divider>
                <a mat-list-item (click)="onTalentList()" [routerLink]="['/list']" routerLinkActive="active">タレントリスト</a>
                <mat-divider></mat-divider>
                <a mat-list-item [routerLink]="['/message']" routerLinkActive="active">一斉メッセージ</a>
                <mat-divider></mat-divider>
                <a mat-list-item [routerLink]="['/pr_contents']" routerLinkActive="active">採用PRコンテンツ</a>
                <mat-divider></mat-divider>
                <a mat-list-item [routerLink]="['/materials']" routerLinkActive="active">PDF資料</a>
                <mat-divider></mat-divider>
                <a mat-list-item [routerLink]="['/job_offer']" routerLinkActive="active">求人情報管理</a>
                <mat-divider></mat-divider>
                <a mat-list-item [routerLink]="['/company']" routerLinkActive="active">企業情報</a>
                <mat-divider></mat-divider>
                <a mat-list-item [routerLink]="['/notice_url']" routerLinkActive="active">告知用URL</a>
                <mat-divider></mat-divider>
                <a mat-list-item [routerLink]="['/'+Constant.rtAnalytics]" routerLinkActive="active">アナリティクス</a>
                <mat-divider></mat-divider>
                <a mat-list-item [routerLink]="['/guide']" routerLinkActive="active">CaLinの使い方</a>
                <mat-divider></mat-divider>
              </ng-container>
              <a mat-list-item (click)="onCalinInformation()" routerLinkActive="active">お知らせ　<span class="font-small">{{ newsDate }}</span></a>
              <mat-divider></mat-divider>
              <a mat-list-item (click)="onRequestForCaLin()" routerLinkActive="active">質問・要望はこちら</a>
              <mat-divider></mat-divider>
            </mat-nav-list>
            <div class="information">
              <a class="infomation-link" mat-list-item (click)="onAgreement()">
                <img class="information-icon" src="assets/talent-list/information_link_icon.png" />利用規約</a
              >
              <a class="infomation-link" mat-list-item (click)="onCalin()" routerLinkActive="active">
                <img class="information-icon" src="assets/talent-list/information_link_icon.png" />運営企業</a
              >
              <a class="infomation-link" mat-list-item (click)="onPrivacy()" routerLinkActive="active">
                <img
                  class="information-icon"
                  src="assets/talent-list/information_link_icon.png"
                />プライバシーポリシー</a
              >
            </div>
          </div>
        </mat-sidenav>
        <mat-sidenav-content [class.no-menu]="menuDisabled">
          <router-outlet></router-outlet>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </div>
  </div>
</ng-template>
