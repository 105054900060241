import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Constant } from './../../constant';
import { CommonUtil } from '../../util/common-util';
import { ToastUtil } from '../../util/toast-util';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from './../../auth/auth.service';
import { GeneralYesNoDialogComponent } from '../general-yes-no-dialog/general-yes-no-dialog.component';
import { sprintf } from 'sprintf-js';

@Component({
  selector: 'app-company-message-dialog',
  templateUrl: './company-message-dialog.component.html',
  styleUrl: './company-message-dialog.component.css'
})
export class CompanyMessageDialogComponent implements OnInit {
  public form: FormGroup;
  public companyInfo;
  public readonly Constant = Constant;
  public isSaving = false;
  public showSpinner = true;
  private apiPath = '/company';

  public title = 'Message（トップページ）'
  public maxLenHeadline = 50;
  public maxLenHeadDesc = 300;

  constructor(
    public dialogRef: MatDialogRef<CompanyMessageDialogComponent>,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private auth: AuthService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.getCompanyData();
  }

  /* テンプレート起動 */
  // 更新
  onSave() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast(Constant.empty, Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.isSaving = false;
      return;
    }
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    this.toastUtil.clearAllShowingToast();
    this.dataUpdate();
  }

  // キャンセル
  onCancel() {
    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: {msg: sprintf(Constant.companyMsgConfirmEditCancel, this.title), check: false }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        this.dialogRef.close('');
      }
    });
  }

  // 企業情報を取得する
  private getCompanyData() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.showSpinner = false;
      return;
    }
    this.showSpinner = true;
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);

      this.commonUtil
        .apiGet(this.apiPath, options)
        .then(res => {
          if (res.data) {
            this.companyInfo = res.data.main;
            this.initForm();
            this.showSpinner = false;
          }
          this.commonUtil.debug().log(this.companyInfo);
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.showSpinner = false;
          this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
          this.dialogRef.close();
        });
    });
  }

  private initForm() {
    this.form = new FormGroup({
      headline: new FormControl(this.companyInfo.message_headline, [Validators.required, Validators.maxLength(this.maxLenHeadline)]),
      desc: new FormControl(this.companyInfo.message_desc, [Validators.required, Validators.maxLength(this.maxLenHeadDesc)]),
    });
  }

  // 更新実行
  private dataUpdate() {
    if (!this.commonUtil.isOnline()) {
      this.toastUtil.clearAllShowingToast();
      this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
      this.isSaving = false;
      return;
    }

    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        type: Constant.companyUpdateTypeMessage,
        message_headline: this.commonUtil.replaceSpace(this.form.value.headline),
        message_desc: this.commonUtil.replaceSpace(this.form.value.desc),
      };
      this.commonUtil
        .apiPut(this.apiPath, options)
        .then(res => {
          if (res.status === Constant.OK) {
            this.toastUtil.showInformationToast('', Constant.msgResultSaveSuccess, Constant.toastShowMiliSec);
            this.dialogRef.close(res.status);
          }
        })
        .catch(err => {
          this.commonUtil.debug().log(err);
          this.toastUtil.showErrorToast('', Constant.msgSaveFailedRetry, Constant.toastShowMiliSec);
          this.isSaving = false;
        });
    });
  }
}

