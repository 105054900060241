import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { AuthService } from '../auth/auth.service';
import { GeneralYesNoDialogComponent } from '../dialog/general-yes-no-dialog/general-yes-no-dialog.component';
import { QuestionDetailDialogComponent } from '../dialog/question-detail-dialog/question-detail-dialog.component';
import { CommonUtil } from '../util/common-util';
import { ToastUtil } from '../util/toast-util';
import { Constant } from './../constant';
import { QuestionEditDialogComponent } from './../dialog/question-edit-dialog/question-edit-dialog.component';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {
  public readonly Constant = Constant;
  public questions = null;
  public showSpinner = true;
  public questionsMaxLen = 20;
  private orderMin = 1;
  private apiPath = '/question';

  constructor(
    private auth: AuthService,
    private title: Title,
    private commonUtil: CommonUtil,
    private toastUtil: ToastUtil,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.title.setTitle(Constant.pageTitleQuestion + Constant.pageTitleCommon);
  }

  ngOnInit(): void {
    window.scroll(0, 0);
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    this.getQuestionList();
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.commonUtil.checkRefreshToken(this.auth);
  }

  // 閉じる
  onClose() {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    this.router.navigate(['notice_url']);
  }

  // 新規作成・編集
  onEdit(index?) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    let questData;
    let type;
    if (index != null) {
      questData = this.questions[index];
      type = Constant.modeEdit;
    } else {
      questData = {
        title: Constant.empty,
        question: Constant.empty,
        type: Constant.aqAnswerTypeTextId,
        order_no: this.orderMin,
        select_item: []
      };
      type = Constant.modeAdd;
    }

    const dialogRef = this.dialog.open(QuestionEditDialogComponent, {
      width: Constant.questionEditDialogWidth,
      maxHeight: Constant.jobOfferDialogMaxHeight,
      minHeight: '500px',
      autoFocus: false,
      disableClose: true,
      data: {
        type: type,
        question: questData
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== Constant.empty) {
        this.getQuestionList();
      }
    });
  }

  // 詳細画面表示
  onDetail(index) {
    let questData;
    questData = this.questions[index];

    const dialogRef = this.dialog.open(QuestionDetailDialogComponent, {
      width: Constant.questionEditDialogWidth,
      maxHeight: Constant.jobOfferDialogMaxHeight,
      minHeight: '500px',
      autoFocus: false,
      data: {
        index: index,
        question: questData
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        if (result.isEdit) {
          // 編集画面を表示する
          this.onEdit(result.index);
        }
      }
    });
  }

  // コピー
  onCopy(index) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    // コピー元データで編集ダイアログを開く
    const questData = {
      title: this.questions[index].title,
      question: this.questions[index].question,
      type: this.questions[index].type,
      order_no: this.questions[index].order_no,
      select_item: this.questions[index].select_item,
    };
    const type = Constant.modeAdd;

    const dialogRef = this.dialog.open(QuestionEditDialogComponent, {
      width: Constant.questionEditDialogWidth,
      maxHeight: Constant.jobOfferDialogMaxHeight,
      minHeight: '500px',
      autoFocus: false,
      disableClose: true,
      data: {
        type: type,
        question: questData
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== Constant.empty) {
        this.getQuestionList();
      }
    });
  }

  // 削除
  onDel(id) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }

    const dialogRef = this.dialog.open(GeneralYesNoDialogComponent, {
      width: Constant.dialogWidth,
      autoFocus: false,
      data: { msg: Constant.aqMsgComfirmDel }
    });
    dialogRef.afterClosed().subscribe(isOK => {
      if (isOK) {
        // 削除のAPI呼び出し
        this.delQuestion(id);
      }
    });
  }

  /* API */
  // 質問一覧取得
  private getQuestionList() {
    if (!this.commonUtil.checkOnline()) {
      this.showSpinner = false;
      return;
    }

    // ぐるぐる表示
    this.showSpinner = true;
    this.questions = null;

    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);

      this.commonUtil
        .apiGet(this.apiPath, options)
        .then(res => {
          this.questions = res.data.data;
          this.orderMin = res.data.order_min;
          this.showSpinner = false;

          // 質問の選択肢取得
          this.getQuestionItem();
        })
        .catch(err => {
          this.showSpinner = false;
          this.toastUtil.showErrorToast('', Constant.msgNetworkError, Constant.toastShowMiliSec);
        });
    });


  }

  private getQuestionItem() {
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      this.questions.forEach(q => {
        const apiPath = this.apiPath + '/item/' + q.id;

        this.commonUtil
        .apiGet(apiPath, options)
        .then(res => {
          q.select_item = res.data;
        })
        .catch(err => {
        });
      });
    });
  }

  // 削除のAPI呼び出し
  private delQuestion(id) {
    if (!this.commonUtil.checkOnline()) {
      return;
    }
    this.showSpinner = true;
    Auth.currentSession().then(session => {
      const options = this.auth.createApiHeader(session);
      options['body'] = {
        id: id
      };

      this.commonUtil
        .apiDel(this.apiPath, options)
        .then(res => {
          this.toastUtil.showInformationToast(Constant.empty, Constant.msgNoticeDel, Constant.toastShowMiliSec);
          this.getQuestionList();
        })
        .catch(err => {
          this.toastUtil.showErrorToast(Constant.empty, Constant.msgNoticeDelFailed2, Constant.toastShowMiliSec);
          this.getQuestionList();
        });
    });
  }
}
