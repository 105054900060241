<mat-dialog-content>
  <div class="content">
    <div class="spinner center" *ngIf="showSpinner">
      <mat-spinner></mat-spinner>
    </div>
    <div class="header" *ngIf="!showSpinner">
      <ng-container *ngIf="mode == Constant.dcTypeEdit">
        <div class="header-left" *ngIf="material.release_status == Constant.pcReleaseStatusRelease">
          <div class="release_status released">
            公開中
          </div>
          @if (material.limited_flag == 1) {
            <img class="limited-icon" src="assets/common/lock_blue.svg">
          }
        </div>
        <div class="header-left" *ngIf="material.release_status == Constant.pcReleaseStatusStop">
          <div class="release_status stopped">
            非公開
          </div>
          @if (material.limited_flag == 1) {
            <img class="limited-icon" src="assets/common/lock_gray.svg">
          }
        </div>
      </ng-container>
      <ng-container *ngIf="mode == Constant.dcTypeAdd">
        <div class="release_status new">新規作成</div>
      </ng-container>
      <div class="description">作成後、資料（PDF）は変更できません。差し替えの場合は、作り直してください。</div>
    </div>
    <form [formGroup]="form" *ngIf="form">
      <div class="input-container">
        <div class="label">
          <img src="assets/notice/mark_blue.png" srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            " /> 資料
            <span class="required"> {{ Constant.prefixRequire }}</span>
            <span class="normal"> ※PDF、容量5Mまで、16:9サイズ推奨</span>
        </div>
        <div class="flex">
          <div class="pdf" *ngIf="documentUrl">
            <div *ngIf="showDocumentSpinner" class="spinner">
              <mat-spinner diameter="60"></mat-spinner>
            </div>
            <pdf-viewer [src]="documentUrl" class="pdf-viewer" [show-all]="false" [page]="documentPage"
            [fit-to-page]="true" [original-size]="false" [zoom-scale]="'page-fit'" [autoresize]="true" [render-text]="false"
            (after-load-complete)="pdfLoadComplete($event)" (error)="pdfError()"
            (page-rendered)="pdfRendered($event)"></pdf-viewer>
            <div class="pdf-viewer-navi" *ngIf="documentTotalPage>0">
              <div class="leftbtn">
                <button mat-flat-button (click)="prevBtn()" [disabled]="documentPage==1">＜</button>
                <button mat-flat-button (click)="nextBtn()" [disabled]="documentPage==documentTotalPage">＞</button>
              </div>
              <span>{{ documentPage }} / {{ documentTotalPage }}</span>
              <div class="rightbtn">
                <button mat-icon-button (click)="onPdf()"><mat-icon>fullscreen</mat-icon></button>
              </div>
            </div>
          </div>
          <div class="pdf" *ngIf="!documentUrl">
            <div class="no-image-frame">
              <div class="noimage">
                No Image
              </div>
            </div>
            <div class="pdf-viewer-navi">
              <div class="leftbtn">
                <button mat-flat-button disabled>＜</button>
                <button mat-flat-button disabled>＞</button>
              </div>
            </div>
          </div>
          @if(mode == Constant.dcTypeAdd) {
          <div class="buttons">
            <div mat-icon-button (click)="documentFileInput.click()">
              <img
                src="assets/joboffer/image_upload.png"
                srcset="
                  assets/joboffer/image_upload.png    1x,
                  assets/joboffer/image_upload@2x.png 2x,
                  assets/joboffer/image_upload@3x.svg 3x
                "
              />
              <input
                hidden
                type="file"
                #documentFileInput
                accept="application/pdf"
                (change)="onChangeImage($event)"
              />
            </div>
            <div mat-icon-button (click)="onImageDelete()">
              <img
                src="assets/joboffer/image_delete.png"
                srcset="
                  assets/joboffer/image_delete.png    1x,
                  assets/joboffer/image_delete@2x.png 2x,
                  assets/joboffer/image_delete@3x.svg 3x" />
            </div>
          </div>
          }
        </div>
      </div>

      <div class="input-container">
        <div class="label">
          <img src="assets/notice/mark_blue.png" srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            " /> タイトル
            <span class="required">{{ Constant.prefixRequire }}</span>
        </div>
        <div class="label-count" [class.length-red]="title.value.length>maxLenTitle">{{ title.value.length }}/{{ maxLenTitle }}</div>
        <input
          class="input-text"
          formControlName="title"
          #title
          placeholder="タイトル"
          [maxlength]="maxLenTitle"
        />
      </div>

      <div class="input-container">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          /> PICK UP表示設定
        </div>
        <div class="label-desc"
        >PICK UPした資料は、ホーム（トップページ）にも表示されます。<br>
        PICK UPは1つの資料だけに設定でき、他資料のチェックは自動で解除されます。
        </div>
        <div class="pickup-container">
          <mat-checkbox color="primary" formControlName="top_flag"
          (change)="changeTopFlg($event.checked)">この資料をPICK UPする</mat-checkbox>
        </div>
      </div>

      <div class="input-container">
        <div class="label">
          <img
            src="assets/notice/mark_blue.png"
            srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            "
          /> 一覧ページ表示設定
        </div>
        <div class="label-desc"
        >「表示しない」とした場合、資料一覧ページなどで本資料が表示されません。
        <br>ただし、本資料のURLをメッセージで送るなどして、ページに直接アクセスした場合は、誰でも資料を閲覧できます。
        </div>
        <div class="indent-radio">
          <mat-radio-group formControlName="limited_flag">
            <mat-radio-button color="primary" [value]="0">
              表示する
            </mat-radio-button>
            <mat-radio-button color="primary" [value]="1">
              表示しない
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="center">
  <button mat-raised-button (click)="onCancel()">キャンセル</button>
  <button mat-raised-button (click)="onSave()" [disabled]="showSpinner || form.invalid || isSaving || !documentUrl" class="yes-btn" *ngIf="mode == Constant.dcTypeAdd">
    作成して公開
  </button>
  <button mat-raised-button (click)="onSave()" [disabled]="showSpinner || form.invalid || isSaving" class="yes-btn" *ngIf="mode == Constant.dcTypeEdit">
    保存
  </button>
  <mat-spinner diameter="28" *ngIf="isSaving" class="spinner"></mat-spinner>
</mat-dialog-actions>

