import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Constant } from '../constant';
import { CommonUtil } from '../util/common-util';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-userguide',
  templateUrl: './userguide.component.html',
  styleUrls: ['./userguide.component.css']
})
export class UserguideComponent implements OnInit {
  public readonly Constant = Constant;

  constructor(
    private commonUtil: CommonUtil,
    private elementRef: ElementRef,
    private title: Title,
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.title.setTitle(Constant.pageTitleUserGuide + Constant.pageTitleCommon);
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    this.commonUtil.checkRefreshToken(this.auth);
  }

  // 質問・要望はこちら
  onRequestForCaLin() {
    this.commonUtil.sendGAEvent(Constant.gaCategoryButtons, Constant.gaActionRequest);
    window.open(Constant.requestForCalinUrl);
  }

  // キャリンの活用例
  onCaseForCalin() {
    window.open('case');
  }


  // ページ内リンク
  onScroll(stepNo) {
    const el = this.elementRef.nativeElement.querySelector('#step' + stepNo);
    window.scrollTo(0, el.offsetTop);
  }

}
