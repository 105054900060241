<mat-dialog-content>
  <div class="content">
    <div class="header">
      <div class="title">{{ title }}</div>
      <div class="description">ここでは、トップページ上部で表示される情報を登録します。</div>
    </div>
    <div class="spinner center" *ngIf="showSpinner">
      <mat-spinner></mat-spinner>
    </div>
    <form [formGroup]="form" *ngIf="!showSpinner && form">
      <!-- ヘッドライン -->
      <div class="input-container">
        <div class="label">
          <img src="assets/notice/mark_blue.png" srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            " /> ヘッドライン
          <span class="required">{{ Constant.prefixRequire }}</span>
        </div>
        <div class="indent">
          <div class="label-count" *ngIf="headline" [class.length-red]="headline.value.length>maxLenHeadline">{{ headline.value.length }}/{{ maxLenHeadline }}</div>
          <input
            class="input-text" #headline
            formControlName="headline"
            [maxlength]="maxLenHeadline" />
        </div>
      </div>
      <!-- ヘッドラインの説明 -->
      <div class="input-container">
        <div class="label">
          <img src="assets/notice/mark_blue.png" srcset="
              assets/notice/mark_blue.png    1x,
              assets/notice/mark_blue@2x.png 2x,
              assets/notice/mark_blue@3x.svg 3x
            " /> ヘッドラインの説明・ボディコピーなど
          <span class="required">{{ Constant.prefixRequire }}</span>
        </div>
        <div class="indent">
          <div class="label-count" *ngIf="desc" [class.length-red]="desc.value.length>maxLenHeadDesc">{{ desc.value.length }}/{{ maxLenHeadDesc }}</div>
          <textarea
            class="input-textarea"
            formControlName="desc"
            rows="12" #desc></textarea>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="center">
  <button mat-raised-button (click)="onCancel()">キャンセル</button>
  <button mat-raised-button (click)="onSave()"
  [disabled]="showSpinner || !form || form.invalid || isSaving" class="yes-btn">
    保存
  </button>
  <mat-spinner diameter="28" *ngIf="isSaving" class="spinner"></mat-spinner>
</mat-dialog-actions>
